<div class="flex justify-center">
  <div class="flex items-center rounded-3xl bg-indigo-50/5 p-1">
    @for (tab of tabs(); track $index) {
      <button
        (click)="setActiveTab(tab)"
        [ngClass]="{
          'bg-blue-500/20 text-indigo-50': selectedTab() === tab,
          'text-indigo-50/60 hover:bg-indigo-50/10': selectedTab() !== tab,
        }"
        class="rounded-[20px] px-3 py-2 text-xs transition-colors duration-300 sm:px-6 sm:py-3 sm:text-sm">
        {{ tab | titlecase }}
      </button>
    }
  </div>
</div>
