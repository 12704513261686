@switch (elementType) {
  @case ('button') {
    <button [class]="classes" [disabled]="disabled">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  }
  @default {
    <a [href]="href" [target]="target" [class]="classes">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }
}

<ng-template #content>
  <ng-content></ng-content>
  {{ text }}

  @if (loading) {
    <app-loading-indicator size="sm"></app-loading-indicator>
  }
</ng-template>
