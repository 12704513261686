import { Component, input, model, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-set',
  templateUrl: './tab-set.component.html',
  standalone: false,
})
export class TabSetComponent implements OnInit {
  tabs = input<string[]>([]);
  selectedTab = model<string>();

  ngOnInit() {
    if (this.tabs().length > 0 && !this.selectedTab()) {
      // Set the first tab as the default selected tab
      this.selectedTab.set(this.tabs()[0]);
    }
  }

  setActiveTab(tab: string) {
    this.selectedTab.set(tab);
  }
}
